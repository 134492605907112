import { Tabs, TabsProps, } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import customerStore from '../../../stores/customerStore';
import organizationsStore from '../../../stores/organizationsStore';
import permissionStore from '../../../stores/permissionStore';
import separatorStore from '../../../stores/separatorStore';
import { OrganizationModel } from '../../../types/OrganizationModel';
import { SeparatorModel } from '../../../types/SeparatorModel';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import ModalComponent from '../../organisms/ModalComponent';
import PermissionList from './PermissionList';
import { editValidationSchema, validationSchema } from './schema';
import SeparatorInformantion from './SeparatorInformantion';
import Permission from '../../molecules/Permission';
import { PermissionSeparator } from '../../../permissions/PermissionSeparator';

const breadCrumb = [
    {
        title: <a href="/separador">Listagem de Separadores</a>,
    },
    {
        title: <p>Cadastro de Separador</p>,
    },
]

const CreateSeparator: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { selectedCountry } = customerStore;
    const { separator, loading } = separatorStore;
    const { permissions } = permissionStore;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const [activeTabKey, setActiveTabKey] = useState('1');

    useEffect(() => {
        const getInformation = async () => {
            if (uuid) {
                await separatorStore.getSeparatorByUuid(uuid);
                await permissionStore.getListUserPermissions(uuid);
                if (separatorStore.separator?.countryCode && customerStore.countriesList.length > 0) {
                    // Busca e define máscara em campo de telefone conforme ddd de país
                    var countryPhoneDigit = customerStore.countriesList.find(
                        (i) => i.option.phoneDigit == separatorStore.separator!.countryCode
                    );
                    customerStore.setSelectedCountry(countryPhoneDigit.option);
                }
            }
        };

        const getOrganization = async () => {
            await organizationsStore.getListOwn();
        };

        const getCountry = async () => {
            await customerStore.getCountries();
        };

        getCountry();
        getOrganization();
        getInformation();

        return () => {
            permissionStore.reset();
            customerStore.reset();
            separatorStore.reset();
        };
    }, [uuid]);


    const initialValues: any = {
        firstName: separator?.firstName || '',
        lastName: separator?.lastName || '',
        email: separator?.email || '',
        phone: separator?.phone || '',
        uuid: separator?.uuid || '',
        countryCode: { value: separator?.countryCode, label: separator?.countryCode, option: selectedCountry },
        qrCode: separator?.qrCode || '',
        organization: { value: separator?.organization?.name, label: separator?.organization?.uuid, option: separator?.organization },
    };

    const handleSubmit = async (values: {
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
        countryCode: { label: string, value: string, };
        qrCode?: string;
        organization: { value: string, label: string, option: OrganizationModel }
    }) => {
        let response;

        const createProps: SeparatorModel = {
            ...values,
            organization: values?.organization?.option,
            countryCode: values.countryCode.label,
        }

        if (createProps?.qrCode !== undefined) {
            delete createProps.qrCode
        }
        if (createProps?.uuid !== undefined) {
            delete createProps.uuid
        }
        if (createProps?.permission !== undefined) {
            delete createProps.permission
        }

        if (uuid) {
            response = await separatorStore.updateSeparator(createProps, uuid);
        } else {
            response = await separatorStore.createSeparator(createProps);
        }

        if (!response.error) {
            await permissionStore.setUserPermissions(response.data.uuid, permissions);
            listRouter();
        }
    };


    const listRouter = () => {
        navigate(`/separador`);
    };


    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Informação Geral',
            children: <SeparatorInformantion setIsModalOpen={setIsModalOpen} />,
        },
        {
            key: '2',
            label: 'Permissões',
            children: <PermissionList />,
        },
    ];

    const onChange = (key: string) => {
        setActiveTabKey(key);
    };

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-4 lg:p-6" title="Cadastro de Separador" description='Tela para cadastro de Separador'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={uuid ? editValidationSchema : validationSchema}
                >
                    <Form>
                        <Tabs
                            className="mb-0"
                            type="card"
                            activeKey={activeTabKey}
                            onChange={onChange}
                            items={items}
                        />

                        <div className="flex justify-end">
                            <Permission permissionKeys={[PermissionSeparator.PUT, PermissionSeparator.POST]} buttonType>
                                <ActionButton htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                            </Permission>
                        </div>
                    </Form>
                </Formik >
            }
            <ModalComponent
                title="QR Code"
                content={<img src={separator?.qrCode} alt='QR Code' />}
                isModalOpen={isModalOpen}
                handleOk={() => setIsModalOpen(false)}
                handleCancel={() => setIsModalOpen(false)}
            />
        </>
    );
});

export default CreateSeparator;