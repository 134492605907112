import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { Space, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE, TEXT_BUTTON_HEADER_CREATE } from '../../../config/config';
import useQueryParams, { buildParamsFromQuery } from '../../../hooks/useQueryParams';
import organizationsStore from '../../../stores/organizationsStore';
import { exportToExcel } from '../../../utils/excelFunction';
import { getSortField, getSortOrder, getUniqueFilters } from '../../../utils/tableFunctions';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import FileUpload from '../../atoms/FileUploadComponent';
import ContentBox from '../../molecules/ContentBox';
import TableCardComponent from '../../molecules/TableCardComponent';
import DrawerBottom from '../../organisms/DrawerButton';
import ModalComponent from '../../organisms/ModalComponent';
import DrawerFilterOrganization from './DrawerFilterOrganization';
import FormOrganization from './FormOrganization';
import Permission from '../../molecules/Permission';
import { PermissionOrganization } from '../../../permissions/PermissionOrganization';
import { useAuth } from '../../protected/ProviderAuth';

interface DataType {
    organization: any;
    email: any;
    fullName: any;
    active: boolean;
    hexColorCode: string;
    shortName: string;
    name: string;
    uuid: string;
    key: React.Key;
}

interface QueryParams {
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
    name__icontains?: string;
    fullName__icontains?: string;
}

// BreadCrumb
const breadCrumb = [
    {
        title: <p>Lista de Organização</p>,
    },
]

const ListOrganization: React.FC = observer(() => {
    const { organizationList, loading, page, totalElements, defaultparams, pageSize, sort } = organizationsStore;
    const [queryParams, setQueryParams] = useQueryParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();
    const [bottomOptions, openBottomOptions] = useState<any>(false);
    const [openFilter, setOpenFilter] = useState<any>();
    let formikSubmit: () => void;
    const listRouter = () => { navigate(`/organizacao/cadastro`); }
    const navigate = useNavigate();
    const { isColumnVisible } = useAuth();


    useEffect(() => {
        const mappings = {
            page: 'page',
            pageSize: 'pageSize',
            name__icontains: 'name__icontains',
            fullName__icontains: 'fullName__icontains',
            sort: 'sort',
        };

        const params = buildParamsFromQuery(queryParams, mappings);

        const fetchData = async () => {
            const urlParams = new URLSearchParams({
                page: String(params.page),
                pageSize: String(params.pageSize ? params.pageSize : DEFAULT_PAGE_SIZE),
                sort: String(params.sort)
            });

            params.filter.forEach((filter: string) => urlParams.append('filter', filter));

            await organizationsStore.getList(urlParams);
        };

        fetchData();
    }, []);

    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const handleDeleteItem = () => {
        organizationsStore.deleteOrganization(itemSelected.uuid);
        setIsModalOpen(false);
    };

    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            filters: getUniqueFilters(organizationList, 'name'),
            onFilter: (value, record) => record.name.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('name', queryParams.sort),
            sorter: true,
        },
        {
            title: 'Organização',
            dataIndex: 'fullName',
            key: 'fullName',
            filters: getUniqueFilters(organizationList, 'fullName'),
            onFilter: (value, record) => record.fullName.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('organization__fullName', queryParams.sort),
            sorter: true,
        },
        {
            title: 'Editar',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            hidden: !isColumnVisible(PermissionOrganization.PUT),
            width: 80,
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <a href={`/organizacao/editar/${record.uuid}`}>
                        <div><EditOutlined /></div>
                    </a>
                </Space>
            ),
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            hidden: !isColumnVisible(PermissionOrganization.DELETE),
            width: 80,
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div ><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];

    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        const params = buildParams(pagination, sorter, queryParams);
        await organizationsStore.getList(params);
    };


    const buildParams = (
        pagination: any,
        sorter: any,
        queryParams: QueryParams
    ) => {
        const getSort = getSortField(sorter);
        const currentPage = pagination.current || pagination;
        const pageSize = pagination.pageSize || queryParams.size || DEFAULT_PAGE_SIZE;
        const paramsUrl = {
            pageSize: pageSize,
            page: currentPage,
            sort: getSort || sort || queryParams.sort || '-date_created',
            fullName__icontains: queryParams.fullName__icontains,
            name__icontains: queryParams.name__icontains
        }

        // Usando URLSearchParams para montar múltiplos parâmetros `filter`
        const params = new URLSearchParams({
            page: currentPage,
            pageSize: String(DEFAULT_PAGE_SIZE),
            sort: getSort || sort || queryParams.sort || '-date_created',
        });

        if (queryParams.name__icontains) {
            params.append('filter', `name__icontains=${queryParams.name__icontains}`);
        }
        if (queryParams.fullName__icontains) {
            params.append('filter', `fullName__icontains=${queryParams.fullName__icontains}`);
        }

        setQueryParams(paramsUrl);
        return params;
    };


    const handleExport = async () => {
        const params = new URLSearchParams();

        if (queryParams.name__icontains) {
            params.append('filter', `name__icontains=${queryParams.name__icontains}`);
        }
        if (queryParams.fullName__icontains) {
            params.append('filter', `fullName__icontains=${queryParams.fullName__icontains}`);
        }
        const dataExport = await organizationsStore?.export(params);

        if (dataExport) {
            exportToExcel(dataExport, "Tabela de Organização.csv");
        }
    };

    const handleImport = async (imports: any) => {
        const formData = new FormData();
        formData.append("file", imports, "data.csv");
        await organizationsStore.import(formData);
    };

    const initialValues = {
        name__icontains: queryParams.name__icontains || '',
        fullName__icontains: queryParams.fullName__icontains || '',
    };

    const handleSubmit = async (values: any) => {
        const newParams: any = {
            name__icontains: values.name__icontains || undefined,
            fullName__icontains: values.fullName__icontains || undefined,
        };

        // Usando URLSearchParams para montar múltiplos parâmetros `filter`
        const params = new URLSearchParams({
            page: '1',
            pageSize: String(DEFAULT_PAGE_SIZE),
        });

        if (queryParams.sort) {
            params.append('sort', String(queryParams.sort));
        }

        if (newParams.name__icontains) {
            params.append('filter', `name__icontains=${newParams.name__icontains}`);
        }
        if (newParams.fullName__icontains) {
            params.append('filter', `fullName__icontains=${newParams.fullName__icontains}`);
        }

        // Definindo os query params atualizados
        setQueryParams(newParams);

        // Chamando a API com os parâmetros múltiplos
        await organizationsStore.getList(params);
    };

    const editRouter = (item: any) => {
        navigate(`/organizacao/editar/${item.uuid}`);
    }

    const deleteItem = (item: any) => {
        const response = organizationsStore.deleteOrganization(item.uuid);
        return response
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionOrganization.POST} textButton={TEXT_BUTTON_HEADER_CREATE} onClick={() => listRouter()} />

            <ContentBox className="mb-4 p-6 hidden lg:block" title="Listagem de Organizações" description='Tela de listagem de Organizações'>
                <FormOrganization
                    setSubmitForm={(submitForm) => {
                        formikSubmit = submitForm;
                    }}
                    queryParams={queryParams} setQueryParams={setQueryParams}
                />
            </ContentBox >

            <ContentBox className="mb-4 p-6 hidden lg:block" description='Lista de Organizações' actions={
                <>
                    <Permission permissionKeys={[PermissionOrganization.IMPORT]} removeType>
                        <FileUpload onFileProcessed={handleImport} requiredColumns={['Nome', 'Estrutura', 'Rua', 'Número', 'Bairro', 'Cidade', 'Estado', 'CEP']} title="Importar Arquivo" />
                    </Permission>

                    <Permission permissionKeys={[PermissionOrganization.EXPORT]} removeType>
                        <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                    </Permission>
                </>
            }>
                <Table
                    className="table-custom"
                    columns={columns}
                    dataSource={organizationList.map((item: any, index: number) => ({
                        ...item,
                        key: item.uuid || index,
                    }))}
                    onChange={onChange}
                    childrenColumnName="notChildren"
                    loading={loading}
                    size="middle"
                    bordered={false}
                    pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: totalElements,
                        showSizeChanger: true,
                        pageSizeOptions: ['20', '40', '60'],
                    }}
                />
            </ContentBox>

            {/* Responsive Component */}
            <TableCardComponent
                itemData={organizationList}
                titlePage="Organizações"
                subTitlePage="Tela de listagem de Organizações"
                titleKey="name"
                subTitleKey="fullName"
                initialValues={initialValues}
                totalElements={totalElements}
                pageSize={pageSize}
                current={page}
                onChange={onChange}
                editRouter={isColumnVisible(PermissionOrganization.PUT) ? editRouter : undefined}
                deleteItem={isColumnVisible(PermissionOrganization.DELETE) ? deleteItem : undefined}
                handleSubmit={handleSubmit}
                inputName="name__icontains"
                placeholder="Pesquisar Organização"
                loading={loading}
                actions={
                    <>
                        <div style={{ background: '#FFFAF2' }} onClick={() => openBottomOptions(true)} className="bg-slate-50 flex min-w-10 min-h-10 justify-center rounded-full">
                            <MoreOutlined />
                        </div>
                    </>
                }
            />

            <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir a Organização <b>{itemSelected?.name}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalOpen(false)}
            />

            <DrawerFilterOrganization queryParams={queryParams} setQueryParams={setQueryParams} isModalOpen={openFilter} setIsModalOpen={setOpenFilter} />

            <DrawerBottom openMenu={bottomOptions} setOpenMenu={openBottomOptions}>
                <p className="h-8" onClick={() => {
                    setOpenFilter(true);
                    openBottomOptions(false);
                }}>Filtro</p>

                <Permission permissionKeys={[PermissionOrganization.POST]} removeType>
                    <p className="h-8" onClick={() => listRouter()}>Cadastrar Organização</p>
                </Permission>

                <Permission permissionKeys={[PermissionOrganization.EXPORT]} removeType>
                    <p className="h-8" onClick={() => {
                        handleExport();
                        openBottomOptions(false);
                    }}>Exportar para Excel</p>
                </Permission>
            </DrawerBottom>
        </>
    );
});

export default ListOrganization;