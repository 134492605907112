import { Empty, message } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { Dispatch } from 'react';
import { useParams } from 'react-router-dom';
import allocationStore from '../../../../stores/allocationStore';
import customerStore from '../../../../stores/customerStore';
import lockersStore from '../../../../stores/lockersStore';
import AutocompleteComponent from '../../../atoms/AutocompleteComponent';
import InputComponent from '../../../atoms/InputComponet';
import SelectComponent from '../../../atoms/SelectComponent';
import ContentBox from '../../../molecules/ContentBox';
import LockerMap from '../../../organisms/LockerMap';
import LockerListMobile from '../LockerListMobile';

interface AllocationInformationProps {
    selectedPorts: any;
    setSelectedPorts: Dispatch<any>;
}

const AllocationInformation: React.FC<AllocationInformationProps> = observer(({ setSelectedPorts, selectedPorts }) => {
    const { uuid } = useParams<{ uuid: string }>();
    const { statusList } = allocationStore;
    const { customerSelect } = customerStore;
    const { lockerSelect } = lockersStore;
    const { allocationList, locker } = lockersStore;

    const handleCustomerSearch = async (name: string) => {
        await customerStore.getCustomerAsyncSelect(name);
    };

    const handleLockerSearch = async (name: string) => {
        await lockersStore.getLockerAsyncSelect(name);
    };

    const searchLocker = async (uuid: string) => {
        setSelectedPorts([]);
        await lockersStore.getLockerByUuid(uuid);
    }

    const handleSelectPort = (item: any) => {
        if (!uuid) {
            if (item.name.toLowerCase() !== 'monitor') {
                if (item.isAvailable === true) {
                    setSelectedPorts((prevSelected: any) => {
                        const isAlreadySelected = prevSelected.some((port: any) => port.container.uuid === item.uuid);

                        if (isAlreadySelected) {
                            // Remove o item se já estiver selecionado
                            return prevSelected.filter((port: any) => port.container.uuid !== item.uuid);
                        }

                        // Adiciona o item com a nova estrutura ao array
                        return [
                            ...prevSelected,
                            {
                                container: item,
                                inLocker: false,  // ou o valor desejado
                                isActive: true     // ou o valor desejado
                            }
                        ];
                    });
                } else {
                    message.error('Esta porta já está alocada. Por favor, selecione outra.');
                }
            } else {
                message.error('Não é possivel selecionar o monitor para alocação');
            }
        } else {
            message.error('Alocação já iniciada. Para selecionar uma nova porta, finalize ou crie uma nova alocação.');
        }
    };


    return (
        <>
            <ContentBox className="mb-4 p-4 lg:p-6" borderCustom='rounded-b-lg' title="Cadastro de Alocação" description='Tela para cadastro de Alocação'>
                <div className="flex w-full gap-4 pb-4 flex-col lg:flex-row">
                    {uuid && <InputComponent disabled label="Código" name="code" id="code" />}
                    <InputComponent label="Código externo" name="externalCode" id="externalCode" />
                    <AutocompleteComponent
                        name='locker'
                        label='Locker'
                        disabled={uuid ? true : false}
                        fetchOptions={(name) => handleLockerSearch(name)}
                        options={lockerSelect ?? []}
                        onChange={(e) => searchLocker(e.option.value)}
                    />

                    {!uuid &&
                        <AutocompleteComponent
                            name='customer'
                            label='Cliente'
                            fetchOptions={(name) => handleCustomerSearch(name)}
                            options={customerSelect ?? []}
                        />
                    }
                </div>

                {uuid &&
                    <>
                        <div className="flex w-full gap-4 pb-4 flex-col lg:flex-row">
                            <AutocompleteComponent
                                name='customer'
                                label='Cliente'
                                fetchOptions={(name) => handleCustomerSearch(name)}
                                options={customerSelect ?? []}
                            />
                            <SelectComponent label="Status" name="status" options={statusList} />
                            <InputComponent disabled label="Dt. Criação" name="dateCreated" id="dateCreated" />

                        </div>

                        <div className="flex w-full gap-4 pb-4 flex-col lg:flex-row">
                            <InputComponent disabled label="Dt. Alocação" name="dateAllocated" id="dateAllocated" />
                            <InputComponent disabled label="Dt. Retirada" name="dateRetrieved" id="dateRetrieved" />
                            <InputComponent disabled label="Dt. Envio Mensagem" name="dateEmailNotification" id="dateEmailNotification" />

                        </div>

                        <div className="flex w-full gap-4 pb-4 flex-col lg:flex-row">
                            <InputComponent disabled label="Dt. Envio E-mail" name="dateMessageNotification" id="dateMessageNotification" />
                            <InputComponent disabled label="Organização" name="fullName" id="fullName" />
                        </div>
                    </>
                }
            </ContentBox>

            <ContentBox className="mb-4 p-6 hidden lg:block" title="Selecione a Porta de Alocação" description='Selecione as Portas para Alocação' actions={
                <div>
                    <div className="flex gap-2 items-center">
                        <div className="w-3 h-3 bg-locker-background rounded-full"></div>
                        <p>Portas Alocadas</p>
                    </div>
                    <div className="flex gap-2 items-center">
                        <div className="w-3 h-3 bg-blue-100 rounded-full"></div>
                        <p>Portas Selecionadas</p>
                    </div>
                </div>
            }>
                {allocationList ? <LockerMap handleSelectPort={handleSelectPort} selectedPorts={selectedPorts} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Selecione um locker para escolher as portas de alocação." />}
            </ContentBox>

            <LockerListMobile />
        </>
    );
});

export default AllocationInformation;