import URLS from '../config/urls';
import { AllocationModel } from '../types/AllocationModel';
import BaseAPI from './BaseApi';


class AllocationService {
  async getList(params: any): Promise<any> {
    try {
      const response = await BaseAPI.get(`${URLS.ALLOCATION}`, { params });
      return response; // Retorna a resposta da API
    } catch (e) {
      return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
    }
  }

  async getAllocationByUuid(uuid: string): Promise<any> {
    try {
      const response = await BaseAPI.get(`${URLS.ALLOCATION}/${uuid}`);
      return response;
    } catch (e) {
      return { error: "Falha ao buscar alocação" };
    }
  }

  async getAllocationByCodeAndLockerUuid(code: string, lockerUuid: string): Promise<any> {
    const params = {
      locker: lockerUuid,
    }
    try {
      const response = await BaseAPI.get(`${URLS.ALLOCATION}/by-code/${code}`, { params });
      return response;
    } catch (e) {
      return { error: "Alocação não encontrada." };
    }
  }

  async getLockerReverseList(uuid: string): Promise<any> {
    try {
      const response = await BaseAPI.get(
        `${URLS.ALLOCATION}/locker/${uuid}/reverse-list`
      );
      return response;
    } catch (e) {
      return { error: "Locker sem reversas" };
    }
  }

  async getInLockerList(uuid: string, params: any): Promise<any> {
    try {
      const response = await BaseAPI.get(
        `${URLS.ALLOCATION}/locker/${uuid}/in-locker`,
        params
      );
      return response;
    } catch (e) {
      return { error: "Locker sem alocaçãos" };
    }
  }

  async createAllocation(data: AllocationModel): Promise<any> {
    try {
      const response = await BaseAPI.post(`${URLS.ALLOCATION}/`, data);
      return response;
    } catch (e) {
      return { error: "Falha ao cadastrar alocação" };
    }
  }

  async updateAllocation(uuid: string, data: AllocationModel): Promise<any> {
    try {
      const response = await BaseAPI.put(`${URLS.ALLOCATION}/${uuid}`, data);
      return response;
    } catch (e) {
      return { error: "Falha ao atualizar alocação" };
    }
  }

  async updateAllocationObservation(uuid: string, data: AllocationModel): Promise<any> {
    try {
      const response = await BaseAPI.put(`${URLS.ALLOCATION}/${uuid}/observations`, data);
      return response;
    } catch (e) {
      return { error: "Falha ao atualizar alocação" };
    }
  }


  async deleteAllocation(uuid: string): Promise<any> {
    try {
      const response = await BaseAPI.delete(`${URLS.ALLOCATION}/${uuid}`);
      return response;
    } catch (e) {
      return { error: "Falha ao deletar alocação" };
    }
  }

  async allocate(uuid: string, data: any): Promise<any> {
    try {
      const response = await BaseAPI.put(
        `${URLS.ALLOCATION}/${uuid}/allocate`,
        data
      );
      return response;
    } catch (e) {
      return { error: "Falha ao registrar alocação" };
    }
  }

  async allocationFinished(uuid: string): Promise<any> {
    try {
      const response = await BaseAPI.put(
        `${URLS.ALLOCATION}/${uuid}/allocation-finished`
      );
      return response;
    } catch (e) {
      return { error: "Falha ao registrar conclusao de alocação de pedido" };
    }
  }

  async deallocate(uuid: string, data: any): Promise<any> {
    try {
      const response = await BaseAPI.put(
        `${URLS.ALLOCATION}/${uuid}/deallocate`,
        data
      );
      return response;
    } catch (e) {
      return { error: "Falha ao registrar retirada" };
    }
  }

  async reverse(uuid: string, data: any): Promise<any> {
    try {
      const response = await BaseAPI.put(
        `${URLS.ALLOCATION}/${uuid}/reverse`,
        data
      );
      return response;
    } catch (e) {
      return { error: "Falha ao registrar reversa" };
    }
  }

  async getAllocationStatuses(): Promise<any> {
    try {
      const response = await BaseAPI.get(`${URLS.ALLOCATION}/statuses`);
      return response;
    } catch (e) {
      return { error: "Falha ao buscar status" };
    }
  }

  async export(params: any): Promise<any> {
    try {
      const response = await BaseAPI.get(URLS.ALLOCATION + "/csv", { params });
      return response;
    } catch (e) {
      return { error: "Falha ao gerar csv" };
    }
  }

  async sendMessageNewAllocation(uuid: string): Promise<any> {
    try {
      const response = await BaseAPI.post(
        `${URLS.ALLOCATION}/${uuid}/send-message-new-allocation`
      );
      return response;
    } catch (e) {
      return { error: "Falha ao enviar mensagem de nova alocação" };
    }
  }

  async sendEmailNewAllocation(uuid: string): Promise<any> {
    try {
      const response = await BaseAPI.post(
        `${URLS.ALLOCATION}/${uuid}/send-email-new-allocation`
      );
      return response;
    } catch (e) {
      return { error: "Falha ao enviar e-mail de nova alocação" };
    }
  }


  async getListStatus(): Promise<any> {
    try {
      const response = await BaseAPI.get(`${URLS.ALLOCATION}/statuses`);
      return response; // Retorna a resposta da API
    } catch (e) {
      return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
    }
  }
}

export default new AllocationService();