import { Tabs, TabsProps } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import administratorStore from '../../../stores/administratorStore';
import customerStore from '../../../stores/customerStore';
import organizationsStore from '../../../stores/organizationsStore';
import permissionStore from '../../../stores/permissionStore';
import { AdministratorModel } from '../../../types/AdministratorModel';
import { CountryModel } from '../../../types/CountryModel';
import { OrganizationModel } from '../../../types/OrganizationModel';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import AdministratorInformation from './AdministratorInformation';
import PermissionList from './PermissionList';
import { editValidationSchema, validationSchema } from './schema';
import Permission from '../../molecules/Permission';
import { PermissionAdministrator } from '../../../permissions/PermissionAdministrator';

const breadCrumb = [
    {
        title: <a href="/administrador">Listagem de Administrador</a>,
    },
    {
        title: <p>Cadastro de Administrador</p>,
    },
]

const CreateAdministrator: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { administrator, loading } = administratorStore;
    const { selectedCountry } = customerStore;
    const { permissions } = permissionStore;
    const [organizationSelected, setOrganizationSelected] = useState<OrganizationModel>();
    const [activeTabKey, setActiveTabKey] = useState('1');

    const navigate = useNavigate();

    useEffect(() => {
        const getInformation = async () => {
            if (uuid) {
                await administratorStore.getAdministratorByUuid(uuid);
                await permissionStore.getListUserPermissions(uuid);

                if (administratorStore.administrator?.countryCode && customerStore.countriesList.length > 0) {
                    // Busca e define máscara em campo de telefone conforme ddd de país
                    var countryPhoneDigit = customerStore.countriesList.find(
                        (i) => i.option.phoneDigit == administratorStore.administrator!.countryCode
                    );
                    customerStore.setSelectedCountry(countryPhoneDigit.option);
                }
            }
        };

        const getOrganization = async () => {
            await organizationsStore.getListOwn();
        };

        const getCountry = async () => {
            await customerStore.getCountries();
        };

        getCountry();
        getOrganization();
        getInformation();

        return () => {
            permissionStore.reset();
            customerStore.reset();
            administratorStore.reset();
        };
    }, [uuid]);

    useEffect(() => {
        if (administrator?.organization) {
            setOrganizationSelected(administrator?.organization);
        }
    }, [administrator?.organization]);

    const initialValues: any = {
        firstName: administrator?.firstName || '',
        lastName: administrator?.lastName || '',
        email: administrator?.email || '',
        phone: administrator?.phone || '',
        uuid: administrator?.uuid || '',
        countryCode: { value: administrator?.countryCode, label: administrator?.countryCode, option: selectedCountry },
        organization: { value: administrator?.organization?.name, label: administrator?.organization?.uuid, option: administrator?.organization },
    };

    const handleSubmit = async (values: {
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
        countryCode: { label: string, value: string, option: CountryModel };
        organization: { label: string, value: string, option: OrganizationModel };
        qrCode?: string;
    }) => {
        let response;

        const createProps: AdministratorModel = {
            ...values,
            organization: values?.organization?.option,
            countryCode: values.countryCode.label,
        }

        if (createProps?.uuid !== undefined) {
            delete createProps.uuid
        }
        if (createProps?.permission !== undefined) {
            delete createProps.permission
        }

        if (uuid) {
            response = await administratorStore.updateAdministrator(createProps, uuid);
        } else {
            response = await administratorStore.createAdministrator(createProps);
        }

        if (!response.error) {
            await permissionStore.setUserPermissions(response.data.uuid, permissions);
            listRouter();
        }
    };


    const listRouter = () => {
        navigate(`/administrador`);
    };


    const onChange = (key: string) => {
        setActiveTabKey(key);
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Informação Geral',
            children: <AdministratorInformation setOrganizationSelected={setOrganizationSelected} organizationSelected={organizationSelected} />,
        },
        {
            key: '2',
            label: 'Permissões',
            children: <PermissionList />,
        },
    ];

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-4 lg:p-6" title="Cadastro de Administrator" description='Tela para cadastro de Administrator'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={uuid ? editValidationSchema : validationSchema}
                >
                    <Form>
                        <Tabs
                            className="mb-0"
                            type="card"
                            activeKey={activeTabKey}
                            onChange={onChange}
                            items={items}
                        />

                        <div className="flex justify-end">
                            <ActionButton htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                        </div>
                    </Form>
                </Formik >
            }
        </>
    );
});

export default CreateAdministrator;